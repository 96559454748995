import { css } from "aphrodite";
import PropTypes from "prop-types";

import ImageFallback from "components/Global/Images/ImageFallback";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  hexagon: {
    position: "relative",
    width: "7.5rem",
    height: "4.375rem",
    backgroundColor: "#64C7CC",
    margin: "2.165rem 0",
    ":before": {
      content: "",
      position: "absolute",
      width: 0,
      borderLeft: "3.75rem solid transparent",
      borderRight: "3.75rem solid transparent",
      bottom: "100%",
      borderbottom: "2.165rem solid #64C7CC",
    },
    ":after": {
      content: "",
      position: "absolute",
      width: 0,
      borderLeft: "3.75rem solid transparent",
      borderRight: "3.75rem solid transparent",
      top: "100%",
      width: 0,
      borderTop: "2.165rem solid #64C7CC",
    },
  },
  badgeTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.2rem",
    color: colours.black,
    margin: 0,
    marginTop: "1.25rem",
    marginBottom: ".5rem",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    textOverflow: "ellipsis",
  },
  badgeDescription: {
    ...gStyles.fontSemiBold,
    fontSize: ".8rem",
    color: colours.grey,
    margin: 0,
    marginBottom: ".5rem",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    textOverflow: "ellipsis",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
};

const EarnedBadge = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const { title, description, children, badge_image_url } = props;

  return (
    <div className={css(styles.container)}>
      <ImageFallback
        imgType="badge"
        src={badge_image_url}
        alt={title || "Badge Image"}
        width={90}
        height={90}
      />
      <p className={css(styles.badgeTitle)}>{title}</p>
      <p className={css(styles.badgeDescription)}>{description}</p>
      {children}
    </div>
  );
};

EarnedBadge.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  badge_image_url: PropTypes.string,
  children: PropTypes.node,
};

EarnedBadge.defaultProps = {
  title: "",
  description: "",
  badge_image_url: "",
  children: null,
};

export default EarnedBadge;
