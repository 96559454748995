import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  profilePanelIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "1.5rem",
    fontSize: "1.25rem",
    color: colours.oldSecondary, // 'inherit',
    position: "relative",
    transition: "400ms color",
  },
  profilePanelIconActive: {
    color: colours.primary,
  },
  profilePanelIconSecondaryIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: ".65rem",
    height: ".65rem",
    fontSize: ".65rem",
    color: colours.positive,
    position: "absolute",
    right: "-5px",
    bottom: 0,
    backgroundColor: colours.white,
    borderRadius: "50%",
  },
};

const ProfilePanelIcon = (props) => {
  const { icon, secondaryIcon, hideSecondaryIcon, isActive } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <span
      className={css(
        styles.profilePanelIcon,
        isActive && styles.profilePanelIconActive
      )}
    >
      <FontAwesomeIcon icon={icon} fixedWidth />

      {!hideSecondaryIcon && secondaryIcon && (
        <div className={css(styles.profilePanelIconSecondaryIcon)}>
          <FontAwesomeIcon icon={secondaryIcon} />
        </div>
      )}
    </span>
  );
};

ProfilePanelIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  secondaryIcon: PropTypes.object,
  hideSecondaryIcon: PropTypes.bool,
  horizontal: PropTypes.bool,
  isActive: PropTypes.bool,
};

ProfilePanelIcon.defaultProps = {
  secondaryIcon: null,
  hideSecondaryIcon: false,
  horizontal: false,
  isActive: false,
};

export default memo(ProfilePanelIcon);
